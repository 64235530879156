import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getLandingPageBySlag } from 'api/LandingPage';
import { createRequest } from 'api/helpers';

// export const fetchLandingPage = createRequest('fetchLandingPage', getLandingPage, (_, { getState }) => {
//     const { attributes } = getState().landingPageState.landingPage.data;
//     return !attributes;
// });

export const fetchCurrentLandingPage = createRequest('landingPageSlice', getLandingPageBySlag);

const landingPageSlice = createSlice({
    name: 'landingPageSlice',
    initialState: {
        landingPage: {
            data: {
                attributes: null,
            },
        },
        landingPageIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchCurrentLandingPage.pending, state => {
            state.landingPageIsLoading = true;
        });
        builder.addCase(fetchCurrentLandingPage.fulfilled, (state, action) => {
            state.landingPageIsLoading = false;
            state.landingPage = action.payload;
        });
    },
});

export const selectLandingPage = createSelector(
    [state => state.landingPageState.landingPage],
    landingPage => landingPage.data[0]?.attributes,
);


export const selectLandingPageHero = createSelector([selectLandingPage], data => data?.hero || {});
export const selectLandingPagePartners = createSelector([selectLandingPage], data => data?.partners || {});
export const selectLandingPageProducts = createSelector([selectLandingPage], data => data?.product_plans || {});
export const selectLandingPageInformations = createSelector([selectLandingPage], data => data?.information_section || {});
export const selectLandingPageReviews = createSelector([selectLandingPage], data => data?.reviews || {});
export const selectLandingPageCta = createSelector([selectLandingPage], data => data?.cta || {});
export const selectLandingPageTags = createSelector([selectLandingPage], data => data?.meta_tags || {});



export const selectLandingPageIsLoading = createSelector([state => state.landingPageState], data => data.landingPageIsLoading);

export default landingPageSlice.reducer;