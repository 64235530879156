import { configureStore } from '@reduxjs/toolkit';
import homepageReducer from './slices/HomepageSlice';
import aboutUsReducer from './slices/AboutSlice';
import generalReducer from './slices/GeneralSlice';
import uiReducer from './slices/UI-Slice';
import blogReducer from './slices/BlogSlice';
import contactReducer from './slices/ContactsSlice';
import featuresReducer from './slices/FeaturesSlice';
import productReducer from './slices/ProductSlice';
import faqReducer from './slices/FAQSlice';
import pricingReducer from './slices/PricingSlice';
import locationsReducer from './slices/LocationsSlice';
import legalReducer from './slices/LegalSlice';
import landingPageReducer from './slices/LandingSlice';

const store = configureStore({
    reducer: {
        homepageState: homepageReducer,
        generalState: generalReducer,
        blogState: blogReducer,
        aboutUsState: aboutUsReducer,
        landingPageState: landingPageReducer,
        contactState: contactReducer,
        faqState: faqReducer,
        featuresState: featuresReducer,
        productState: productReducer,
        pricingState: pricingReducer,
        locationsState: locationsReducer,
        legalState: legalReducer,
        ui: uiReducer,
    },
});

export default store;
