import useAnalyticsEventTracker from "hooks/useAnalyticsEventTracker";
import "./styles.scss"
import { ReactComponent as User } from 'assets/icons/header/user.svg'
import { Link } from "react-router-dom";




const LogBtn = ({ text }) => {
    const eventTracker = useAnalyticsEventTracker({ category: "Navigation", action: "Login Click" });

    return (
        <div className="log-btn-container">
            <Link to={process.env.REACT_APP_DASHBOARD_URL} className="log-btn" onClick={eventTracker}>
                <User />
                {text}
            </Link>
        </div>
    )
}






export default LogBtn;