import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getProductBySlag } from 'api/Product';
import { createRequest } from 'api/helpers';

// export const fetchCurrentProduct = createRequest('fetchCurrentProduct', getProductById);
export const fetchCurrentProduct = createRequest('fetchCurrentProduct', getProductBySlag);

const ProductSlice = createSlice({
    name: 'ProductSlice',
    initialState: {
        currentProduct: {
            data: {},
        },
        currentProductIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchCurrentProduct.pending, (state, action) => {
            state.currentProductIsLoading = true;
        })
        builder.addCase(fetchCurrentProduct.fulfilled, (state, action) => {
            state.currentProductIsLoading = false;
            state.currentProduct = action.payload;
        });
    },
});


export const selectCurrentProduct = createSelector(
    [state => state.productState.currentProduct],
    currentProduct => currentProduct.data[0]?.attributes,
);

export const selectProductHero = createSelector([selectCurrentProduct], data => data?.hero || {});
export const selectProductStatistics = createSelector([selectCurrentProduct], data => data?.statistics || {});
export const selectProductCTA = createSelector([selectCurrentProduct], data => data?.cta || {});
export const selectProductPayments = createSelector([selectCurrentProduct], data => data?.payments || {});
export const selectProductFeatures = createSelector([selectCurrentProduct], data => data?.features || {});
export const selectProductDashboard = createSelector([selectCurrentProduct], data => data?.dashboard || {});
export const selectProductBenefits = createSelector([selectCurrentProduct], data => data?.benefits || {});
export const selectProductLocations = createSelector([selectCurrentProduct], data => data?.locations || {});
export const selectProductFAQ = createSelector([selectCurrentProduct], data => data?.faq || {});
export const selectProductCTAFooter = createSelector([selectCurrentProduct], data => data?.cta_footer || {});
export const selectProductTags = createSelector([selectCurrentProduct], data => data?.meta_tags || {});



export const selectProductIsLoading = createSelector([state => state.productState], data => data.currentProductIsLoading);
export default ProductSlice.reducer;
