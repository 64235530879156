import { useEffect, useState } from 'react';

export const useImageLoaded = (dependencies, isLoading) => {
    const [isImageLoading, setIsImageLoading] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            const images = document.querySelectorAll('img');
            let loadedImagesCount = 0;

            const handleImageLoad = () => {
                loadedImagesCount++;
                if (loadedImagesCount === images.length) {
                    setIsImageLoading(true);
                }
            };

            images.forEach(image => {
                if (image.complete) {
                    handleImageLoad();
                } else {
                    image.addEventListener('load', handleImageLoad);
                }
            });

            return () => {
                images.forEach(image => {
                    image.removeEventListener('load', handleImageLoad);
                });
            };
        }

    }, [dependencies, isLoading]);
    return isImageLoading;
};

// import { useEffect, useRef, useState } from "react";

// export const useImageLoaded = () => {
//     const [isImageLoading, setIsImageLoading] = useState(true);
//     const imagesRef = useRef([]);

//     useEffect(() => {
//         const handleImageLoad = () => {
//             const loadedImages = imagesRef.current.filter(image => image.complete);
//             if (loadedImages.length === imagesRef.current.length) {
//                 setIsImageLoading(false);
//             }
//         };

//         const images = Array.from(document.getElementsByTagName('img'));
//         imagesRef.current = images;

//         images.forEach(image => {
//             if (image.complete) {
//                 handleImageLoad();
//             } else {
//                 image.addEventListener('load', handleImageLoad);
//             }
//         });

//         return () => {
//             images.forEach(image => {
//                 image.removeEventListener('load', handleImageLoad);
//             });
//         };
//     }, []);

//     return isImageLoading;
// };
