import "./styles.scss"
import { Link } from "react-router-dom";
import { ReactComponent as Google } from 'assets/icons/googleIcon.svg'




const GoogleButton = () => {

    return (
        <Link to={`${process.env.REACT_APP_OAUTH_URL}`} className="google-btn">
            <Google />
            Start with Google
        </Link>
    )
}






export default GoogleButton;