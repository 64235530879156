import axios from 'axios';
import { API } from 'api/constants';

export const getProductById = params => {
    const { productId } = params;
    return axios.get(
        API + `/products/${productId}?populate=hero.image &populate=statistics.card.icon&populate=cta&populate=payments.card.payments_method.icon&populate=features&populate=dashboard.image&populate=dashboard.card_one.icon&populate=dashboard.card_two.icon&populate=dashboard.card_three.icon&populate=benefits.cards.icon&populate=locations&populate=faq.question_section.question&populate=cta_footer`,
    );
};


export const getProductBySlag = params => {
    const { productSlug } = params;
    return axios.get(
        API + `/products?filters[slug][$eq]=${productSlug}&populate=hero.image &populate=statistics.card.icon&populate=cta&populate=payments.card.payments_method.icon&populate=features&populate=dashboard.image&populate=dashboard.card_one.icon&populate=dashboard.card_two.icon&populate=dashboard.card_three.icon&populate=benefits.cards.icon&populate=locations&populate=faq.question_section.question&populate=cta_footer&populate=meta_tags.tags`,
    );
};