import './styles.scss';
import { getIcon } from './helpers';
import { Link } from 'react-router-dom';
import { selectisContactFormOpen, setContactFormOpenOpen } from 'store/slices/UI-Slice';
import { useDispatch, useSelector } from 'react-redux';




//Type: purple/white/outline/outline-rotate
//Size: S/M/L
//hasArrow = true/false


const Button = ({ text, type = "purple", size = "M", hasArrow = true, onClick = null, to = null, target = false, forPopup = false, disabled = false }) => {
    const dispatch = useDispatch()
    const isOpen = useSelector(selectisContactFormOpen);
    const handleButtonClick = () => {
        if (onClick) {
            onClick();
        }
        else {
            if (forPopup) {
                dispatch(setContactFormOpenOpen(!isOpen))
                document.documentElement.classList.toggle('unscrolled');
            }
        }

    };

    return target ?
        (
            <Link to={to} target="_blank" className={`button button--${type} button--${size} ${hasArrow ? "button--with-arrow" : ""}`}>
                {hasArrow && getIcon(type)}
                <span>{text}</span>
                {hasArrow && getIcon(type)}
            </Link>
        )
        :
        (
            to ?
                <Link to={to} className={`button button--${type} button--${size} ${hasArrow ? "button--with-arrow" : ""}`}>
                    {hasArrow && getIcon(type)}
                    <span>{text}</span>
                    {hasArrow && getIcon(type)}
                </Link>
                :
                <button className={`button button--${type} button--${size} ${hasArrow ? "button--with-arrow" : ""}`} onClick={handleButtonClick} disabled={disabled}>
                    {hasArrow && getIcon(type)}
                    <span>{text}</span>
                    {hasArrow && getIcon(type)}
                </button>
        );
}

export default Button;