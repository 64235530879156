import React, { useState } from 'react';
import { ReactComponent as Question } from 'assets/icons/header/question.svg';
import './styles.scss';

const AskExpert = ({ text }) => {
    const [isLoading, setIsLoading] = useState(false);

    const loadMavaScript = () => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = "https://widget.mava.app";
            script.async = true;
            script.setAttribute("widget-version", "v2");
            script.id = "MavaWebChat";
            script.setAttribute("data-token", `${process.env.REACT_APP_MAVA_TAG}`);
            script.setAttribute("data-hide-mava-launcher", "true");
            script.onload = () => {
                // Wait for Mava to initialize
                const checkMavaInitialized = () => {
                    if (window.MavaWebChatToggle) {
                        resolve();
                    } else {
                        setTimeout(checkMavaInitialized, 100);
                    }
                };
                checkMavaInitialized();
            };
            script.onerror = reject;
            document.body.appendChild(script);
        });
    };
    
    const handleClick = async () => {
        if (window.MavaWebChatToggle) {
            window.MavaWebChatToggle();
        } else if (!isLoading) {
            setIsLoading(true);
            try {
                await loadMavaScript();
                if (window.MavaWebChatToggle) {
                    window.MavaWebChatToggle();
                } else {
                    console.error('Mava chat widget failed to initialize');
                }
            } catch (error) {
                console.error('Failed to load Mava script:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };
    
    return (
        <button onClick={handleClick} className='ask-expert' id="mava-webchat-launcher" disabled={isLoading}>
            <Question />
            {isLoading ? 'Loading...' : text}
        </button>
    );
}

export default AskExpert;