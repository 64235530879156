import { Outlet } from "react-router-dom";
import './styles.scss';
import Footer from './Footer';
import Header from "./Header";
import ContactsPopup from 'components/Popups/ContactsPopup';
import Cookies from "components/Popups/Cookies";
import { useSelector } from 'react-redux';
import { selectIsHeaderBannerClosed } from "store/slices/UI-Slice";
import { Suspense } from "react";

const Layout = (props) => {
    const isHeaderBannerClosed = useSelector(selectIsHeaderBannerClosed)
    return (
        <>
            <Header />
            <main className={`${isHeaderBannerClosed ? "" : "with-banner"}`}>
                <Suspense>
                    <Outlet />
                </Suspense>
            </main>
            <Suspense>
                <Cookies />
                <ContactsPopup />
            </Suspense>
            <Footer />
        </>
    )
}

export default Layout;