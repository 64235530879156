import { MAIN_URL } from 'api/constants';
import * as dayjs from 'dayjs';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import JSZip from 'jszip';

export const scrollToSection = (className, offset = 50) => {
    window.scrollTo({
        top: document?.querySelector(`.${className}`)?.getBoundingClientRect().top + window.scrollY - offset,
        behavior: 'smooth',
    });
};

export const getImageUrl = imageData => {
    const url = imageData?.data?.attributes.url;
    if (url) {
        return url;
    } else {
        return 'error';
    }
};

export const getFormatedDate = date => {
    return dayjs(date).format('DD.MM.YYYY');
};

export const getTextContent = content => {
    if (content && content.length) {
        return <BlocksRenderer content={content} />;
    } else return null;
};

export const copyTextToClipboard = text => {
    navigator.clipboard.writeText(text).catch(err => {
        console.error('Error copying link to clipboard', err);
    });
};
export const generateMetaTags = (tags, filterType, pageTitle = '', pageDescription = '') => {
    if (!tags) return null;

    const getMetaContent = (tagName, fallback) => {
        const tag = tags.find(t => t.name === tagName);
        return tag ? tag.content : fallback;
    };

    const title = getMetaContent('title', pageTitle);
    const description = getMetaContent('description', pageDescription);

    const ogTitle = getMetaContent('og:title', title);
    const ogDescription = getMetaContent('og:description', description);
    const twitterTitle = getMetaContent('twitter:title', title);
    const twitterDescription = getMetaContent('twitter:description', description);

    const additionalTags = [
        { property: 'og:title', content: ogTitle },
        { property: 'og:description', content: ogDescription },
        { name: 'twitter:title', content: twitterTitle },
        { name: 'twitter:description', content: twitterDescription },
    ];

    if (filterType === 'title') {
        const metaTitle = tags.find(t => t.name === 'title');
        return metaTitle ? String(metaTitle.content) : pageTitle;
    }

    const result = tags
        .map(tag => {
            if (!tag.name) {
                return null;
            }
            if (filterType === 'meta') {
                if (
                    !tag.name.startsWith('titleTemplate') &&
                    !tag.name.startsWith('defaultTitle') &&
                    !tag.name.startsWith('title')
                ) {
                    return tag.name.startsWith('og:')
                        ? { property: tag.name, content: tag.content }
                        : { name: tag.name, content: tag.content };
                } else {
                    return null;
                }
            } else if (filterType === 'titleTemplate') {
                return tag.name === 'titleTemplate' ? String(tag.content) : null;
            } else if (filterType === 'defaultTitle') {
                return tag.name === 'defaultTitle' ? String(tag.content) : null;
            } else if (filterType === 'title') {
                const metaTitle = tags.find(t => t.name === 'title');
                if (metaTitle) {
                    return String(metaTitle.content);
                } else {
                    return null;
                }
            } else {
                return null;
            }
        })
        .filter(tag => tag !== null);

    return [...result, ...additionalTags];
};

export const downloadImages = async imageUrls => {
    if (imageUrls.length === 1) {
        const url = imageUrls[0];
        const link = document.createElement('a');
        link.href = url;
        link.download = url.split('/').pop();
        link.click();
    } else if (imageUrls.length > 1) {
        const zip = new JSZip();
        const folderName = 'Ping-logos';

        await Promise.all(
            imageUrls.map(async (url, index) => {
                try {
                    const response = await fetch(url);
                    const blob = await response.blob();

                    const fileName = getFileNameFromUrl(response.url);

                    zip.file(`${folderName}/${fileName}`, blob);
                } catch (error) {
                    console.error('Error', error);
                }
            }),
        );
        zip.generateAsync({ type: 'blob' }).then(content => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(content);
            link.download = 'Ping_Proxy_logo_pack.zip';
            link.click();
        });
    }
};

const getFileNameFromUrl = url => {
    const matches = url.match(/\/([^\/?#]+)[^\/]*$/);
    if (matches && matches.length > 1) {
        return matches[1];
    }
    return `image_${new Date().getTime()}`;
};